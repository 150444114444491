// 产地溯源相关接口
import { http } from "../src/utils/index";

export const getPlasticSourceList = (params: object) => {
    return http({
        url: '/api/unAuth/trace/origin',
        method: 'GET',
        params
    })
}

// 获取权威认证列表
export const getAuthList = (params:object) => {
    return http({
        url: '/api/unAuth/trace/cert',
        method: 'GET',
        params
    })
}

// 获取种植溯源
export const getPlantData = (params:object) => {
    return http({
        url: '/api/unAuth/trace/plant',
        method: "GET",
        params
    })
}

// 根据溯源获取主体信息
export const getTracePrimaryInfo = (params:object) => {
    return http({
        url: '/api/unAuth/trace/info',
        method: 'GET',
        params
    })
}

// 根据防伪码id获取主体信息
export const getCodePrimaryInfo = (params:object) => {
    return http({
        url: '/api/unAuth/trace/codeinfo',
        method: 'GET',
        params
    })
}

// 获取生产主体信息
export const getProductionInfo = (params:object) => {
    return http({
        url: '/api/unAuth/trace/index',
        method: 'GET',
        params
    })
}

// 获取加工信息
export const getProcessList = (params:object) => {
    return http({
        url: '/api/unAuth/trace/process',
        method: 'GET',
        params
    })
}

// 验证防伪码
export const verifyCode = (params:object) => {
    return http({
        url: '/api/unAuth/trace/verify',
        method:'GET',
        params,
        expel:true
    })
}

// 获取收购单详情
export const getOrderDetailApi = (data:object)=>{
    return http({
        url:`/api/unAuth/feedback/info`,
        method:"get",
        params:data
    })
}

// 提交收购单反馈
export const postFeedApi = (data:object)=>{
    return http({
        url:`/api/unAuth/feedback/index`,
        method:"post",
        data
    })
}
// 获取收购单反馈
export const getFeedApi = (id:string)=>{
    return http({
        url:`/api/unAuth/feedback/detail?feedId=`+id,
        method:"get",
    })
}
// 获取重定向链接
export const getTempUrl = ()=>{
    return http({
        url:`/api/login/findOpenWxAppUrl`,
        method:"get",
    })
}


// 获取七牛云上传token
export const getQinNiuTokenApi = (data:object)=>{
	return http({
			url:`/qiniu/uploadNotLoginSinge`,
			method:"post",
			data
	})
}
// 代理到货单详情
export const getAgentDeliverOrderApi = (params:object)=>{
	return http({
			url:`/api/receiving/note/agency`,
			method:"get",
			params
	})
}
// 保存代理到货单
export const postAgentDeliverOrderApi = (data:object)=>{
	return http({
			url:`/api/receiving/note/saveAgency`,
			method:"post",
			data
	})
}
// 代理验收单详情
export const getAgentCheckOrderApi = (params:object)=>{
	return http({
			url:`/api/acceptance/note/agency`,
			method:"get",
			params
	})
}
// 保存代理验收单
export const postAgentCheckOrderApi = (data:object)=>{
	return http({
			url:`/api/acceptance/note/saveAgency`,
			method:"post",
			data
	})
}